define("discourse/plugins/waze-semi-category/discourse/components/waze-semi-category-dialog-content", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/plugins/waze-semi-category/discourse/lib/fuse", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _fuse, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="waze-semi-category-dialog-content">
    <wz-text-input
      placeholder={{I18n "waze.semi_categories.list.search_placeholder"}}
      {{on "change" (action this.onQueryChange)}}
      {{didInsert this.onInsert}}
    >
      <span
        slot="leading-icon"
        class="waze-semi-category-dialog-content-search-icon"
      >
        {{d-icon "w-search"}}
      </span>
    </wz-text-input>
    <div class="waze-semi-category-dialog-content-categories">
      <div class="waze-semi-category-dialog-content-categories-grid">
        {{#each this.filteredCategories as |c|}}
          <WazeCategoryBox @category={{c}} />
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "JkjMB8sf",
    "block": "[[[10,0],[14,0,\"waze-semi-category-dialog-content\"],[12],[1,\"\\n  \"],[11,\"wz-text-input\"],[16,\"placeholder\",[28,[37,2],[\"waze.semi_categories.list.search_placeholder\"],null]],[4,[38,3],[\"change\",[28,[37,4],[[30,0],[30,0,[\"onQueryChange\"]]],null]],null],[4,[38,5],[[30,0,[\"onInsert\"]]],null],[12],[1,\"\\n    \"],[10,1],[14,\"slot\",\"leading-icon\"],[14,0,\"waze-semi-category-dialog-content-search-icon\"],[12],[1,\"\\n      \"],[1,[28,[35,7],[\"w-search\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"waze-semi-category-dialog-content-categories\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"waze-semi-category-dialog-content-categories-grid\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"filteredCategories\"]]],null]],null],null,[[[1,\"        \"],[8,[39,10],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"c\"],false,[\"div\",\"wz-text-input\",\"I18n\",\"on\",\"action\",\"didInsert\",\"span\",\"d-icon\",\"each\",\"-track-array\",\"waze-category-box\"]]",
    "moduleName": "discourse/plugins/waze-semi-category/discourse/components/waze-semi-category-dialog-content.hbs",
    "isStrictMode": false
  });
  class WazeSemiCategoryDialogContent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "query", [_tracking.tracked], function () {
      return "";
    }))();
    #query = (() => (dt7948.i(this, "query"), void 0))();
    get filteredCategories() {
      const categories = this.args.semiCategory.categories || [];
      if (!this.query) {
        return categories;
      }
      const fuse = new _fuse.FuseJs(categories, {
        keys: ["name"],
        threshold: 0.5
      });
      return fuse.search(this.query).map(res => res.item);
    }
    onQueryChange(event) {
      this.query = event.target.value;
    }
    static #_2 = (() => dt7948.n(this.prototype, "onQueryChange", [_object.action]))();
    onInsert(element) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.focus();
          }
        });
      });
      observer.observe(element);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onInsert", [_object.action]))();
  }
  _exports.default = WazeSemiCategoryDialogContent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeSemiCategoryDialogContent);
});