define("discourse/plugins/waze-semi-category/discourse/templates/connectors/category-custom-settings/waze-semi-category-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{I18n "waze.semi_categories.settings.waze_semi_category"}}</h3>
  
  <section class="field">
    <label>{{I18n
        "waze.semi_categories.settings.belongs_to_semi_category"
      }}</label>
    <div class="controls">
      <ComboBox
        @id="waze-semi-category"
        @class="input-xxlarge"
        @content={{this.semiCategories}}
        @value={{@outletArgs.category.custom_fields.waze_semi_category_id}}
        @options={{hash allowAny=false}}
      />
      {{#if @outletArgs.category.custom_fields.waze_semi_category_id}}
        <DButton
          @class="btn-default undo"
          @action={{action "resetSemiCategory"}}
          @icon="undo"
          @label="admin.settings.reset"
        />
      {{/if}}
    </div>
  </section>
  
  <section class="field">
    <label>{{I18n "waze.semi_categories.settings.show_semi_category"}}</label>
    <div class="controls">
      <label class="checkbox-label">
        <Input
          @type="checkbox"
          @checked={{@outletArgs.category.custom_fields.waze_show_semi_category_layout}}
        />
        {{I18n "waze.semi_categories.settings.show_semi_category_above_topics"}}
      </label>
      <b>{{I18n "waze.semi_categories.settings.show_semi_category_hint"}}</b>
    </div>
  </section>
  */
  {
    "id": "tS4vaF6+",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,1],[\"waze.semi_categories.settings.waze_semi_category\"],null]],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,1],[\"waze.semi_categories.settings.belongs_to_semi_category\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@id\",\"@class\",\"@content\",\"@value\",\"@options\"],[\"waze-semi-category\",\"input-xxlarge\",[30,0,[\"semiCategories\"]],[30,1,[\"category\",\"custom_fields\",\"waze_semi_category_id\"]],[28,[37,6],null,[[\"allowAny\"],[false]]]]],null],[1,\"\\n\"],[41,[30,1,[\"category\",\"custom_fields\",\"waze_semi_category_id\"]],[[[1,\"      \"],[8,[39,8],null,[[\"@class\",\"@action\",\"@icon\",\"@label\"],[\"btn-default undo\",[28,[37,9],[[30,0],\"resetSemiCategory\"],null],\"undo\",\"admin.settings.reset\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,1],[\"waze.semi_categories.settings.show_semi_category\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[8,[39,10],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"category\",\"custom_fields\",\"waze_show_semi_category_layout\"]]]],null],[1,\"\\n      \"],[1,[28,[35,1],[\"waze.semi_categories.settings.show_semi_category_above_topics\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"b\"],[12],[1,[28,[35,1],[\"waze.semi_categories.settings.show_semi_category_hint\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@outletArgs\"],false,[\"h3\",\"I18n\",\"section\",\"label\",\"div\",\"combo-box\",\"hash\",\"if\",\"d-button\",\"action\",\"input\",\"b\"]]",
    "moduleName": "discourse/plugins/waze-semi-category/discourse/templates/connectors/category-custom-settings/waze-semi-category-settings.hbs",
    "isStrictMode": false
  });
});